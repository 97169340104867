// extracted by mini-css-extract-plugin
export var cardBack = "postcard-module--cardBack--fcc39";
export var cardBox = "postcard-module--cardBox--f341f";
export var cardFront = "postcard-module--cardFront--7ff68";
export var fineprint = "postcard-module--fineprint--21bc0";
export var greetings = "postcard-module--greetings--d7f9b";
export var greetingsclick = "postcard-module--greetingsclick--c3ad8";
export var gridContainer = "postcard-module--grid-container--0cd08";
export var image = "postcard-module--image--fcad2";
export var left = "postcard-module--left--0fba5";
export var middle = "postcard-module--middle--5dcbe";
export var right = "postcard-module--right--4229d";
export var row = "postcard-module--row--4d825";
export var shake = "postcard-module--shake--c45a6";
export var signature = "postcard-module--signature--90bba";
export var stamp = "postcard-module--stamp--c0249";
export var welcomebox = "postcard-module--welcomebox--68837";